import { QuestionsStep } from '~/pages/rental-application/steps/QuestionsStep';

const RentalQuestions = () => {
  return (
    <div class="relative flex h-full grow flex-col">
      <QuestionsStep />
    </div>
  );
};

export default RentalQuestions;
