import { Component, createMemo, JSX, Show } from 'solid-js';
import { cn } from '~/utils/classnames';

type CheckboxProps = Omit<JSX.InputHTMLAttributes<HTMLInputElement>, 'onInput'> & {
  class?: string;
  id?: string;
  label?: string;
  onInput?: (checked: boolean) => void;
  showLabel?: boolean;
  checkBoxClass?: string;
  checked?: boolean;
};

export const Checkbox: Component<CheckboxProps> = (props) => {
  const id = createMemo(() => props.id || props.label?.replace(/[^a-zA-Z0-9]/g, ''));

  return (
    <div class={cn('inline-flex items-center', props.checkBoxClass)}>
      <label class="relative flex cursor-pointer items-center" data-ripple-dark="true">
        <input
          type="radio"
          class={cn(
            "before:content[''] peer relative size-5 cursor-pointer border-gray-700 before:bg-gray-500",
            'appearance-none rounded-md border transition-all before:absolute before:left-2/4 before:top-2/4',
            'before:block before:size-12 before:-translate-x-2/4 before:-translate-y-2/4 before:rounded-full',
            'before:opacity-0 before:transition-opacity checked:border-primary-color checked:bg-primary-color',
            'checked:before:bg-primary-color hover:before:opacity-10',
            props.class
          )}
          id={id()}
          checked={props.checked}
          onInput={(ev) => props.onInput?.(ev.target.checked)}
        />
        <div class="pointer-events-none absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="size-3.5"
            viewBox="0 0 20 20"
            fill="currentColor"
            stroke="currentColor"
            stroke-width="1">
            <path
              fill-rule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      </label>
      <Show when={props.showLabel && props.label}>
        <div class="ml-4">{props.label}</div>
      </Show>
    </div>
  );
};
