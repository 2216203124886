import { For } from 'solid-js';
import { Checkbox } from '~/components/common/Inputs/Checkbox';

export const Radio = <T,>(props: { items: Array<{ label: string; value: T }>; value: T; onInput: (value: T) => void }) => {
  const idPrefix = Math.random().toString(36).substring(7);
  return (
    <div class="flex items-center gap-4">
      <For each={props.items}>
        {(item, index) => (
          <Checkbox
            showLabel
            id={idPrefix + item.value + index()}
            class="rounded-full"
            label={item.label}
            checked={props.value === item.value}
            onInput={() => {
              props.onInput(props.items[index()].value);
            }}
          />
        )}
      </For>
    </div>
  );
};
